import React, { useState, useEffect } from 'react';
import { Users, Clock, MapPin, Plus } from 'lucide-react';
import { useSocialStore } from '../../stores/socialStore';
import { useAuthStore } from '../../stores/authStore';
import { useGeolocation } from '../../hooks/useGeolocation';
import CreateSpontaneousMeetupModal from './CreateSpontaneousMeetupModal';
import { format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function SpontaneousMeetups() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { location } = useGeolocation();
  const { user } = useAuthStore();
  const { spontaneousMeetups, getNearbyMeetups, joinSpontaneousMeetup, loading, error } = useSocialStore();

  useEffect(() => {
    if (location) {
      getNearbyMeetups(location.coords, 5000); // 5km radius
    }
  }, [location]);

  const handleJoinMeetup = async (meetupId: string) => {
    if (!user) return;
    try {
      await joinSpontaneousMeetup(meetupId, user.uid);
    } catch (error) {
      console.error('Erreur lors de la participation à la rencontre:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Rencontres spontanées</h1>
        {user && (
          <button
            onClick={() => setShowCreateModal(true)}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
          >
            <Plus className="w-5 h-5 mr-2" />
            Créer une rencontre
          </button>
        )}
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="grid gap-6">
        {spontaneousMeetups.map((meetup) => (
          <div
            key={meetup.id}
            className="bg-white rounded-lg shadow-sm hover:shadow-md transition p-6"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{meetup.title}</h3>
                <p className="text-gray-600 mt-1">{meetup.description}</p>
              </div>
              <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                {meetup.category}
              </span>
            </div>

            <div className="grid grid-cols-3 gap-4 mb-6">
              <div className="flex items-center text-gray-500">
                <Clock className="w-5 h-5 mr-2" />
                <div>
                  <p className="text-sm font-medium">Début</p>
                  <p className="text-sm">
                    {format(new Date(meetup.startTime), 'p', { locale: fr })}
                  </p>
                </div>
              </div>

              <div className="flex items-center text-gray-500">
                <MapPin className="w-5 h-5 mr-2" />
                <div>
                  <p className="text-sm font-medium">Lieu</p>
                  <p className="text-sm">{meetup.location.address}</p>
                </div>
              </div>

              <div className="flex items-center text-gray-500">
                <Users className="w-5 h-5 mr-2" />
                <div>
                  <p className="text-sm font-medium">Participants</p>
                  <p className="text-sm">
                    {meetup.participants.length}
                    {meetup.maxParticipants && ` / ${meetup.maxParticipants}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-500">
                Créé {formatDistanceToNow(new Date(meetup.startTime), { 
                  addSuffix: true,
                  locale: fr 
                })}
              </span>
              
              {user && !meetup.participants.includes(user.uid) && (
                <button
                  onClick={() => handleJoinMeetup(meetup.id)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                >
                  Rejoindre
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      <CreateSpontaneousMeetupModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </div>
  );
}