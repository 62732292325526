import { create } from 'zustand';
import { getDistance } from 'geolib';
import { generateAIRecommendations } from '../services/aiService';
import { db } from '../lib/firebase';
import { collection, addDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';

interface SocialState {
  spontaneousMeetups: SpontaneousMeetup[];
  thematicRoutes: ThematicRoute[];
  userPreferences: UserPreferences;
  loading: boolean;
  error: string | null;
  
  createSpontaneousMeetup: (meetup: Omit<SpontaneousMeetup, 'id'>) => Promise<string>;
  joinSpontaneousMeetup: (meetupId: string, userId: string) => Promise<void>;
  getNearbyMeetups: (location: GeolocationCoordinates, radius: number) => Promise<SpontaneousMeetup[]>;
  generateThematicRoute: (preferences: RoutePreferences) => Promise<ThematicRoute>;
  updateUserPreferences: (userId: string, preferences: Partial<UserPreferences>) => Promise<void>;
  getPersonalizedSuggestions: (userId: string) => Promise<Array<Event | Group>>;
}

interface SpontaneousMeetup {
  id: string;
  title: string;
  description: string;
  location: {
    lat: number;
    lng: number;
    address: string;
  };
  category: string;
  creatorId: string;
  participants: string[];
  maxParticipants?: number;
  duration: number;
  startTime: Date;
  status: 'pending' | 'active' | 'completed' | 'cancelled';
}

interface ThematicRoute {
  id: string;
  theme: string;
  description: string;
  points: Array<{
    placeId: string;
    name: string;
    location: {
      lat: number;
      lng: number;
    };
    description: string;
    duration: number;
  }>;
  totalDistance: number;
  estimatedDuration: number;
  difficulty: 'easy' | 'moderate' | 'challenging';
}

interface UserPreferences {
  interests: string[];
  preferredDistance: number;
  availableTimes: Array<{
    day: number;
    startTime: string;
    endTime: string;
  }>;
  spontaneousMeetupPreferences: {
    maxDistance: number;
    preferredCategories: string[];
    minParticipants: number;
  };
}

interface RoutePreferences {
  theme: string;
  maxDistance: number;
  duration: number;
  includeBusinesses: boolean;
  difficulty: 'easy' | 'moderate' | 'challenging';
}

export const useSocialStore = create<SocialState>((set, get) => ({
  spontaneousMeetups: [],
  thematicRoutes: [],
  userPreferences: {
    interests: [],
    preferredDistance: 5000,
    availableTimes: [],
    spontaneousMeetupPreferences: {
      maxDistance: 5000,
      preferredCategories: [],
      minParticipants: 2
    }
  },
  loading: false,
  error: null,

  createSpontaneousMeetup: async (meetupData) => {
    set({ loading: true, error: null });
    try {
      const docRef = await addDoc(collection(db, 'spontaneousMeetups'), {
        ...meetupData,
        status: 'pending',
        createdAt: new Date()
      });
      
      set((state) => ({
        spontaneousMeetups: [...state.spontaneousMeetups, { ...meetupData, id: docRef.id }]
      }));
      
      return docRef.id;
    } catch (error) {
      set({ error: 'Erreur lors de la création de la rencontre spontanée' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  getNearbyMeetups: async (location, radius) => {
    set({ loading: true, error: null });
    try {
      const meetupsRef = collection(db, 'spontaneousMeetups');
      const q = query(
        meetupsRef,
        where('status', '==', 'pending'),
        where('startTime', '>=', new Date())
      );
      
      const querySnapshot = await getDocs(q);
      const meetups = querySnapshot.docs
        .map(doc => ({ ...doc.data(), id: doc.id } as SpontaneousMeetup))
        .filter(meetup => {
          const distance = getDistance(
            { latitude: location.latitude, longitude: location.longitude },
            { latitude: meetup.location.lat, longitude: meetup.location.lng }
          );
          return distance <= radius;
        });
      
      return meetups;
    } catch (error) {
      set({ error: 'Erreur lors de la récupération des rencontres à proximité' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  generateThematicRoute: async (preferences) => {
    set({ loading: true, error: null });
    try {
      const route = await generateAIRecommendations(preferences);
      const docRef = await addDoc(collection(db, 'thematicRoutes'), route);
      return { ...route, id: docRef.id };
    } catch (error) {
      set({ error: 'Erreur lors de la génération du parcours thématique' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  updateUserPreferences: async (userId, preferences) => {
    set({ loading: true, error: null });
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { preferences });
      
      set((state) => ({
        userPreferences: { ...state.userPreferences, ...preferences }
      }));
    } catch (error) {
      set({ error: 'Erreur lors de la mise à jour des préférences' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  getPersonalizedSuggestions: async (userId) => {
    set({ loading: true, error: null });
    try {
      const suggestions = await generateAIRecommendations({
        userId,
        preferences: get().userPreferences,
        includeEvents: true,
        includeGroups: true,
        includeBusinesses: true
      });
      
      return suggestions;
    } catch (error) {
      set({ error: 'Erreur lors de la récupération des suggestions' });
      throw error;
    } finally {
      set({ loading: false });
    }
  }
}));