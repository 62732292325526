import { db } from '../lib/firebase';
import { collection, query, where, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { Location, calculateDistance } from './locationService';
import { startOfDay, endOfDay, startOfWeek, endOfWeek } from 'date-fns';

export interface Event {
  id: string;
  title: string;
  description: string;
  date: Date;
  location: Location & {
    address: string;
  };
  category: string;
  organizer: {
    id: string;
    name: string;
  };
  participants: string[];
  maxParticipants?: number;
  price?: number;
  image?: string;
  tags: string[];
}

export async function createEvent(eventData: Omit<Event, 'id'>): Promise<string> {
  try {
    const eventsRef = collection(db, 'events');
    const docRef = await addDoc(eventsRef, {
      ...eventData,
      date: Timestamp.fromDate(eventData.date),
      createdAt: Timestamp.now()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating event:', error);
    throw new Error('Failed to create event');
  }
}

export async function getTodayEvents(): Promise<Event[]> {
  try {
    const eventsRef = collection(db, 'events');
    const today = new Date();
    const start = startOfDay(today);
    const end = endOfDay(today);

    const q = query(
      eventsRef,
      where('date', '>=', Timestamp.fromDate(start)),
      where('date', '<=', Timestamp.fromDate(end))
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date.toDate()
    })) as Event[];
  } catch (error) {
    console.error('Error fetching today events:', error);
    throw new Error('Failed to fetch today events');
  }
}

export async function getWeekendEvents(): Promise<Event[]> {
  try {
    const eventsRef = collection(db, 'events');
    const now = new Date();
    const weekStart = startOfWeek(now, { weekStartsOn: 6 }); // Saturday
    const weekEnd = endOfWeek(now, { weekStartsOn: 6 }); // Sunday

    const q = query(
      eventsRef,
      where('date', '>=', Timestamp.fromDate(weekStart)),
      where('date', '<=', Timestamp.fromDate(weekEnd))
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date.toDate()
    })) as Event[];
  } catch (error) {
    console.error('Error fetching weekend events:', error);
    throw new Error('Failed to fetch weekend events');
  }
}

export async function getUpcomingEvents(category?: string): Promise<Event[]> {
  try {
    const eventsRef = collection(db, 'events');
    let q = query(
      eventsRef,
      where('date', '>=', Timestamp.now())
    );

    if (category) {
      q = query(q, where('category', '==', category));
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date.toDate()
    })) as Event[];
  } catch (error) {
    console.error('Error fetching upcoming events:', error);
    throw new Error('Failed to fetch upcoming events');
  }
}

export async function getNearbyEvents(
  center: Location,
  radiusInMeters: number = 5000,
  category?: string
): Promise<Event[]> {
  try {
    const events = await getUpcomingEvents(category);
    
    return events.filter(event => {
      const distance = calculateDistance(center, event.location);
      return distance <= radiusInMeters;
    }).sort((a, b) => {
      const distanceA = calculateDistance(center, a.location);
      const distanceB = calculateDistance(center, b.location);
      return distanceA - distanceB;
    });
  } catch (error) {
    console.error('Error fetching nearby events:', error);
    throw new Error('Failed to fetch nearby events');
  }
}

export async function joinEvent(eventId: string, userId: string): Promise<void> {
  try {
    const eventRef = collection(db, 'events');
    const q = query(eventRef, where('id', '==', eventId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      throw new Error('Event not found');
    }

    const event = snapshot.docs[0];
    const eventData = event.data() as Event;

    if (eventData.maxParticipants && eventData.participants.length >= eventData.maxParticipants) {
      throw new Error('Event is full');
    }

    if (eventData.participants.includes(userId)) {
      throw new Error('User already joined this event');
    }

    await addDoc(collection(db, 'events', event.id, 'participants'), {
      userId,
      joinedAt: Timestamp.now()
    });
  } catch (error) {
    console.error('Error joining event:', error);
    throw error;
  }
}

export async function leaveEvent(eventId: string, userId: string): Promise<void> {
  try {
    const eventRef = collection(db, 'events');
    const q = query(eventRef, where('id', '==', eventId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      throw new Error('Event not found');
    }

    const event = snapshot.docs[0];
    const participantsRef = collection(db, 'events', event.id, 'participants');
    const participantQuery = query(participantsRef, where('userId', '==', userId));
    const participantSnapshot = await getDocs(participantQuery);

    if (!participantSnapshot.empty) {
      const participantDoc = participantSnapshot.docs[0];
      await addDoc(collection(db, 'events', event.id, 'participantHistory'), {
        userId,
        joinedAt: participantDoc.data().joinedAt,
        leftAt: Timestamp.now()
      });
      // Remove from current participants
      await participantDoc.ref.delete();
    }
  } catch (error) {
    console.error('Error leaving event:', error);
    throw error;
  }
}