import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, MapPin, Users, Search } from 'lucide-react';
import { useAuthStore } from '../stores/authStore';

export default function Home() {
  const navigate = useNavigate();
  const { location } = useAuthStore();
  
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchInput = (e.currentTarget.elements.namedItem('search') as HTMLInputElement).value;
    navigate(`/search?q=${encodeURIComponent(searchInput)}`);
  };

  const categories = [
    {
      name: 'Culture',
      image: 'https://images.unsplash.com/photo-1566140967404-b8b3932483f5?auto=format&fit=crop&w=800&q=80',
      description: 'Arts, musées et spectacles',
      slug: 'culture'
    },
    {
      name: 'Sport',
      image: 'https://images.unsplash.com/photo-1517649763962-0c623066013b?auto=format&fit=crop&w=800&q=80',
      description: 'Activités sportives',
      slug: 'sport'
    },
    {
      name: 'Musique',
      image: 'https://images.unsplash.com/photo-1511735111819-9a3f7709049c?auto=format&fit=crop&w=800&q=80',
      description: 'Concerts et événements',
      slug: 'musique'
    },
    {
      name: 'Gastronomie',
      image: 'https://images.unsplash.com/photo-1414235077428-338989a2e8c0?auto=format&fit=crop&w=800&q=80',
      description: 'Restaurants et food',
      slug: 'gastronomie'
    },
    {
      name: 'Art',
      image: 'https://images.unsplash.com/photo-1547826039-bfc35e0f1ea8?auto=format&fit=crop&w=800&q=80',
      description: 'Expositions et galeries',
      slug: 'art'
    },
    {
      name: 'Bien-être',
      image: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?auto=format&fit=crop&w=800&q=80',
      description: 'Yoga et relaxation',
      slug: 'bien-etre'
    },
    {
      name: 'Nature',
      image: 'https://images.unsplash.com/photo-1441974231531-c6227db76b6e?auto=format&fit=crop&w=800&q=80',
      description: 'Parcs et randonnées',
      slug: 'nature'
    },
    {
      name: 'Technologie',
      image: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&w=800&q=80',
      description: 'Innovation et numérique',
      slug: 'technologie'
    },
    {
      name: 'Parents & Familles',
      image: 'https://images.unsplash.com/photo-1536640712-4d4c36ff0e4e?auto=format&fit=crop&w=800&q=80',
      description: 'Activités familiales',
      slug: 'parents-familles'
    },
    {
      name: 'Hobbies & Passions',
      image: 'https://images.unsplash.com/photo-1513364776144-60967b0f800f?auto=format&fit=crop&w=800&q=80',
      description: 'Ateliers créatifs',
      slug: 'hobbies-passions'
    },
    {
      name: 'Animaux',
      image: 'https://images.unsplash.com/photo-1450778869180-41d0601e046e?auto=format&fit=crop&w=800&q=80',
      description: 'Rencontres et activités',
      slug: 'animaux'
    },
    {
      name: 'Rencontres',
      image: 'https://images.unsplash.com/photo-1529156069898-49953e39b3ac?auto=format&fit=crop&w=800&q=80',
      description: 'Échanges et networking',
      slug: 'rencontres'
    },
    {
      name: 'Événements locaux',
      image: 'https://images.unsplash.com/photo-1523580494863-6f3031224c94?auto=format&fit=crop&w=800&q=80',
      description: 'Festivals et célébrations',
      slug: 'evenements-locaux'
    },
    {
      name: 'Activités locales',
      image: 'https://images.unsplash.com/photo-1517457373958-b7bdd4587205?auto=format&fit=crop&w=800&q=80',
      description: 'Découvertes et loisirs',
      slug: 'activites-locales'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">Découvrez votre ville autrement</h1>
            <p className="text-xl mb-8">Explorez les lieux, rencontrez des gens et créez des moments inoubliables</p>
            <div className="max-w-2xl mx-auto">
              <form onSubmit={handleSearch} className="relative">
                <input
                  type="text"
                  name="search"
                  placeholder="Rechercher des lieux, événements ou activités..."
                  className="w-full px-6 py-4 rounded-full text-gray-800 shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
                />
                <button 
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700"
                >
                  <Search className="w-5 h-5" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Categories Section */}
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl font-bold mb-6">Explorez par catégorie</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {categories.map((category) => (
              <Link
                key={category.slug}
                to={`/events/category/${category.slug}`}
                className="bg-white rounded-lg shadow-sm hover:shadow-md transition overflow-hidden group"
              >
                <div className="relative h-48">
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex flex-col justify-end p-4">
                    <h3 className="font-semibold text-white text-lg">{category.name}</h3>
                    <p className="text-sm text-white/80">{category.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}