import React, { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { MapPin, Users, ArrowLeft } from 'lucide-react';
import { searchPlaces, PlaceResult } from '../../services/googlePlaces';
import { useGeolocation } from '../../hooks/useGeolocation';

export default function SearchResults() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || '';
  const { location } = useGeolocation();
  const [results, setResults] = useState<PlaceResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchResults() {
      if (!location || !query) return;

      try {
        setLoading(true);
        setError(null);
        const searchResults = await searchPlaces(
          query,
          {
            lat: location.coords.latitude,
            lng: location.coords.longitude
          },
          5000
        );
        setResults(searchResults);
      } catch (err) {
        console.error('Erreur lors de la recherche:', err);
        setError('Impossible de charger les résultats. Veuillez réessayer plus tard.');
      } finally {
        setLoading(false);
      }
    }

    fetchResults();
  }, [query, location]);

  const handlePlaceClick = (place: PlaceResult) => {
    navigate(`/places/${place.id}`, {
      state: {
        name: place.name,
        address: place.address,
        photos: place.photos,
        rating: place.rating,
        types: place.types,
        location: place.location
      }
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <Link to="/" className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-4">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Retour
        </Link>
        <h1 className="text-3xl font-bold text-gray-900 mb-2">
          Résultats pour "{query}"
        </h1>
        <p className="text-gray-600">
          {results.length} résultat{results.length !== 1 ? 's' : ''} trouvé{results.length !== 1 ? 's' : ''}
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {results.map((place) => (
          <div 
            key={place.id}
            onClick={() => handlePlaceClick(place)}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition cursor-pointer"
          >
            <div className="relative h-48">
              <img
                src={place.photos[0] || `https://source.unsplash.com/800x600/?place`}
                alt={place.name}
                className="w-full h-full object-cover"
              />
              {place.types[0] && (
                <div className="absolute top-4 right-4 bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
                  {place.types[0].replace(/_/g, ' ')}
                </div>
              )}
            </div>
            
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{place.name}</h3>
              
              <div className="space-y-2 mb-4">
                <div className="flex items-center text-gray-600">
                  <MapPin className="w-4 h-4 mr-2" />
                  <span className="text-sm">{place.address}</span>
                </div>
                
                {place.rating && (
                  <div className="flex items-center text-gray-600">
                    <Users className="w-4 h-4 mr-2" />
                    <span className="text-sm">Note: {place.rating}/5</span>
                  </div>
                )}
              </div>

              <div className="flex space-x-2">
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePlaceClick(place);
                  }}
                  className="flex-1 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                >
                  Plus d'infos
                </button>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`https://www.google.com/maps/search/?api=1&query=${place.location.lat},${place.location.lng}`, '_blank');
                  }}
                  className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition"
                >
                  Y aller
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {results.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-600">
            Aucun résultat trouvé pour "{query}".
          </p>
        </div>
      )}
    </div>
  );
}