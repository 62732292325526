import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Calendar, MapPin, Users, Tag } from 'lucide-react';
import { Event, getWeekendEvents, getTodayEvents, getNearbyEvents } from '../../services/eventService';
import { useGeolocation } from '../../hooks/useGeolocation';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function Events() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { location } = useGeolocation();
  const { type } = useParams();
  const routerLocation = useLocation();

  useEffect(() => {
    async function fetchEvents() {
      try {
        setLoading(true);
        setError(null);
        let fetchedEvents: Event[] = [];

        switch (type) {
          case 'weekend':
            fetchedEvents = await getWeekendEvents();
            break;
          case 'today':
            fetchedEvents = await getTodayEvents();
            break;
          case 'nearby':
            if (location) {
              fetchedEvents = await getNearbyEvents({
                lat: location.coords.latitude,
                lng: location.coords.longitude
              });
            }
            break;
          default:
            if (location) {
              fetchedEvents = await getNearbyEvents({
                lat: location.coords.latitude,
                lng: location.coords.longitude
              });
            }
        }

        setEvents(fetchedEvents);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Erreur lors du chargement des événements');
      } finally {
        setLoading(false);
      }
    }

    fetchEvents();
  }, [type, location, routerLocation]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">Aucun événement trouvé</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">
        {type === 'weekend' && 'Événements ce week-end'}
        {type === 'today' && 'Événements aujourd\'hui'}
        {type === 'nearby' && 'Événements à proximité'}
        {!type && 'Tous les événements'}
      </h1>

      <div className="grid gap-6">
        {events.map((event) => (
          <div key={event.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="flex">
              {event.image && (
                <div className="w-48 h-48">
                  <img
                    src={event.image}
                    alt={event.title}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              <div className="flex-1 p-6">
                <h2 className="text-xl font-semibold mb-2">{event.title}</h2>
                
                <div className="flex items-center space-x-4 text-sm text-gray-600 mb-3">
                  <span className="flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    {format(event.date, 'PPP', { locale: fr })}
                  </span>
                  <span className="flex items-center">
                    <MapPin className="w-4 h-4 mr-1" />
                    {event.location.name}
                  </span>
                  {event.maxAttendees && (
                    <span className="flex items-center">
                      <Users className="w-4 h-4 mr-1" />
                      {event.attendees.length}/{event.maxAttendees} participants
                    </span>
                  )}
                </div>

                <p className="text-gray-600 mb-4">{event.description}</p>

                <div className="flex flex-wrap gap-2 mb-4">
                  {event.tags.map((tag) => (
                    <span
                      key={tag}
                      className="flex items-center px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
                    >
                      <Tag className="w-3 h-3 mr-1" />
                      {tag}
                    </span>
                  ))}
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    {event.price ? (
                      <span className="text-lg font-semibold">{event.price}€</span>
                    ) : (
                      <span className="text-green-600 font-medium">Gratuit</span>
                    )}
                  </div>
                  <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">
                    Participer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}