import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getPlaceDetails } from '../services/googlePlaces';
import { MapPin, Clock, Star, Phone, Globe, ChevronLeft, Navigation } from 'lucide-react';
import { Link } from 'react-router-dom';

interface PlaceDetails {
  name: string;
  address: string;
  photos: string[];
  rating?: number;
  isOpen?: boolean;
  openingHours?: string[];
  website?: string;
  phone?: string;
  location?: {
    lat: number;
    lng: number;
  };
  url?: string;
  reviews?: Array<{
    author: string;
    rating: number;
    text: string;
    time: number;
  }>;
}

export default function PlaceDetails() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [details, setDetails] = useState<PlaceDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const initialData = location.state;

  useEffect(() => {
    async function fetchDetails() {
      if (!id) return;

      try {
        setLoading(true);
        const placeDetails = await getPlaceDetails(id);
        setDetails(placeDetails);
      } catch (err) {
        console.error('Error fetching place details:', err);
        setError('Impossible de charger les détails du lieu');
      } finally {
        setLoading(false);
      }
    }

    fetchDetails();
  }, [id]);

  const handleNavigation = () => {
    if (details?.location) {
      // Ouvrir dans Google Maps
      const url = details.url || `https://www.google.com/maps/search/?api=1&query=${details.location.lat},${details.location.lng}`;
      window.open(url, '_blank');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <p className="text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Link to="/" className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6">
        <ChevronLeft className="w-5 h-5 mr-1" />
        Retour
      </Link>

      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        {details?.photos && details.photos.length > 0 && (
          <div className="relative h-64 sm:h-96">
            <img
              src={details.photos[0]}
              alt={details.name}
              className="w-full h-full object-cover"
            />
          </div>
        )}

        <div className="p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {details?.name || initialData?.name}
          </h1>

          <div className="flex flex-wrap gap-4 mb-6">
            <div className="flex items-center text-gray-600">
              <MapPin className="w-5 h-5 mr-2" />
              <span>{details?.address || initialData?.address}</span>
            </div>
            {details?.isOpen !== undefined && (
              <div className="flex items-center text-gray-600">
                <Clock className="w-5 h-5 mr-2" />
                <span>{details.isOpen ? 'Ouvert' : 'Fermé'}</span>
              </div>
            )}
            {details?.rating && (
              <div className="flex items-center text-gray-600">
                <Star className="w-5 h-5 mr-2 text-yellow-400" />
                <span>{details.rating}</span>
              </div>
            )}
          </div>

          <div className="flex flex-wrap gap-4 mb-6">
            <button
              onClick={handleNavigation}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            >
              <Navigation className="w-5 h-5 mr-2" />
              Y aller
            </button>

            {details?.phone && (
              <a
                href={`tel:${details.phone}`}
                className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
              >
                <Phone className="w-5 h-5 mr-2" />
                {details.phone}
              </a>
            )}
            {details?.website && (
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
              >
                <Globe className="w-5 h-5 mr-2" />
                Site web
              </a>
            )}
          </div>

          {details?.openingHours && (
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-2">Horaires d'ouverture</h2>
              <ul className="space-y-1 text-gray-600">
                {details.openingHours.map((hours, index) => (
                  <li key={index}>{hours}</li>
                ))}
              </ul>
            </div>
          )}

          {details?.reviews && details.reviews.length > 0 && (
            <div className="mt-8">
              <h2 className="text-lg font-semibold mb-4">Avis</h2>
              <div className="space-y-4">
                {details.reviews.map((review, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4">
                    <div className="flex items-center justify-between mb-2">
                      <span className="font-medium">{review.author}</span>
                      <div className="flex items-center">
                        <Star className="w-4 h-4 text-yellow-400 mr-1" />
                        <span>{review.rating}</span>
                      </div>
                    </div>
                    <p className="text-gray-600">{review.text}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}