import { create } from 'zustand';
import { auth, db } from '../lib/firebase';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  User,
  AuthError
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';

interface AuthState {
  user: User | null;
  profile: UserProfile | null;
  isLoading: boolean;
  error: string | null;
  location: GeolocationPosition | null;
  searchRadius: number;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, profile: UserProfile) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: (profile: Partial<UserProfile>) => Promise<void>;
  updateLocation: (position: GeolocationPosition) => void;
  updateSearchRadius: (radius: number) => void;
  clearError: () => void;
}

export interface UserProfile {
  id: string;
  name: string;
  type: 'user' | 'business';
  interests: string[];
  avatar?: string;
  phone?: string;
  address?: string;
  createdAt?: string;
  notificationPreferences: {
    nearbyPlaces: boolean;
    events: boolean;
    radius: number;
    interests: string[];
    frequency: 'realtime' | 'hourly' | 'daily' | 'weekly';
    quietHours: {
      start: string;
      end: string;
    };
  };
  businessDetails?: {
    businessName: string;
    category: string;
    description: string;
    address: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const handleAuthError = (error: unknown): string => {
  if (error instanceof Error) {
    if ((error as AuthError).code === 'auth/network-request-failed') {
      return 'Erreur de connexion réseau. Veuillez vérifier votre connexion internet.';
    }
    if ((error as AuthError).code === 'auth/too-many-requests') {
      return 'Trop de tentatives. Veuillez réessayer plus tard.';
    }
    if ((error as AuthError).code === 'auth/email-already-in-use') {
      return 'Cette adresse email est déjà utilisée.';
    }
    if ((error as AuthError).code === 'auth/user-not-found') {
      return 'Aucun compte ne correspond à cette adresse email.';
    }
    return error.message;
  }
  return 'Une erreur inattendue est survenue';
};

const retryOperation = async <T>(
  operation: () => Promise<T>,
  retries: number = MAX_RETRIES
): Promise<T> => {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && 
        (error as AuthError).code === 'auth/network-request-failed') {
      await wait(RETRY_DELAY);
      return retryOperation(operation, retries - 1);
    }
    throw error;
  }
};

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  profile: null,
  isLoading: true,
  error: null,
  location: null,
  searchRadius: 1000,

  signIn: async (email, password) => {
    try {
      set({ isLoading: true, error: null });
      const credential = await retryOperation(() => 
        signInWithEmailAndPassword(auth, email, password)
      );
      
      const profileDoc = await retryOperation(() =>
        getDoc(doc(db, 'profiles', credential.user.uid))
      );
      
      if (profileDoc.exists()) {
        set({ 
          user: credential.user, 
          profile: profileDoc.data() as UserProfile,
          isLoading: false 
        });
      } else {
        throw new Error('Profil utilisateur non trouvé');
      }
    } catch (error) {
      const errorMessage = handleAuthError(error);
      set({ 
        error: errorMessage, 
        isLoading: false 
      });
      throw error;
    }
  },

  signUp: async (email, password, profile) => {
    try {
      set({ isLoading: true, error: null });
      const credential = await retryOperation(() =>
        createUserWithEmailAndPassword(auth, email, password)
      );
      
      const newProfile = {
        ...profile,
        id: credential.user.uid,
        createdAt: new Date().toISOString(),
        notificationPreferences: {
          nearbyPlaces: true,
          events: true,
          radius: 1000,
          interests: [],
          frequency: 'realtime',
          quietHours: {
            start: '22:00',
            end: '07:00'
          }
        }
      };
      
      await retryOperation(() =>
        setDoc(doc(db, 'profiles', credential.user.uid), newProfile)
      );
      
      set({ 
        user: credential.user, 
        profile: newProfile,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = handleAuthError(error);
      set({ 
        error: errorMessage, 
        isLoading: false 
      });
      throw error;
    }
  },

  resetPassword: async (email) => {
    try {
      set({ isLoading: true, error: null });
      await retryOperation(() => sendPasswordResetEmail(auth, email));
    } catch (error) {
      const errorMessage = handleAuthError(error);
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  signOut: async () => {
    try {
      await retryOperation(() => firebaseSignOut(auth));
      set({ user: null, profile: null });
    } catch (error) {
      const errorMessage = handleAuthError(error);
      set({ error: errorMessage });
      throw error;
    }
  },

  updateProfile: async (profileUpdate) => {
    const { user, profile } = get();
    if (!user || !profile) return;

    try {
      const updatedProfile = { ...profile, ...profileUpdate };
      await retryOperation(() =>
        setDoc(doc(db, 'profiles', user.uid), updatedProfile)
      );
      set({ profile: updatedProfile });
    } catch (error) {
      const errorMessage = handleAuthError(error);
      set({ error: errorMessage });
      throw error;
    }
  },

  updateLocation: (position) => {
    set({ location: position });
  },

  updateSearchRadius: (radius) => {
    set({ searchRadius: radius });
  },

  clearError: () => {
    set({ error: null });
  }
}));

// Initialize auth state listener
onAuthStateChanged(auth, async (user) => {
  if (user) {
    try {
      const profileDoc = await retryOperation(() =>
        getDoc(doc(db, 'profiles', user.uid))
      );
      
      useAuthStore.setState({ 
        user, 
        profile: profileDoc.exists() ? profileDoc.data() as UserProfile : null,
        isLoading: false 
      });
    } catch (error) {
      useAuthStore.setState({ 
        error: handleAuthError(error),
        isLoading: false 
      });
    }
  } else {
    useAuthStore.setState({ 
      user: null, 
      profile: null, 
      isLoading: false 
    });
  }
});