import React from 'react';
import { Users, MapPin, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

interface GroupCardProps {
  id: string;
  name: string;
  description: string;
  memberCount: number;
  location: {
    city: string;
  };
  image?: string;
  createdAt: Date;
  category: string;
}

export default function GroupCard({
  id,
  name,
  description,
  memberCount,
  location,
  image,
  createdAt,
  category
}: GroupCardProps) {
  return (
    <Link
      to={`/groups/${id}`}
      className="block bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow overflow-hidden"
    >
      <div className="relative h-48">
        <img
          src={image || 'https://images.unsplash.com/photo-1517457373958-b7bdd4587205?auto=format&fit=crop&w=800&q=80'}
          alt={name}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 right-4">
          <span className="px-3 py-1 bg-blue-500 text-white text-sm rounded-full">
            {category}
          </span>
        </div>
      </div>
      
      <div className="p-4">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{name}</h3>
        <p className="text-gray-600 text-sm mb-4 line-clamp-2">{description}</p>
        
        <div className="flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            <Users className="w-4 h-4 mr-1" />
            <span>{memberCount} membres</span>
          </div>
          
          <div className="flex items-center">
            <MapPin className="w-4 h-4 mr-1" />
            <span>{location.city}</span>
          </div>
        </div>
        
        <div className="mt-4 pt-4 border-t border-gray-100 flex items-center justify-between text-xs text-gray-400">
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            <span>
              Créé {formatDistanceToNow(new Date(createdAt), { addSuffix: true, locale: fr })}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}