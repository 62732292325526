import React, { useState, useRef } from 'react';
import { Search, UserCircle } from 'lucide-react';
import { useAuthStore } from '../stores/authStore';
import { Link, useNavigate } from 'react-router-dom';
import { searchPlaces } from '../services/googlePlaces';
import { useGeolocation } from '../hooks/useGeolocation';
import { useClickOutside } from '../hooks/useClickOutside';
import NotificationBell from './notifications/NotificationBell';

interface SearchResult {
  id: string;
  name: string;
  address: string;
  distance: number;
}

export default function Navbar() {
  const { user, profile, signOut } = useAuthStore();
  const { location } = useGeolocation();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useClickOutside(userMenuRef, () => setShowUserMenu(false));
  useClickOutside(searchRef, () => setShowResults(false));

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowResults(true);

    if (query.length < 2 || !location) {
      setSearchResults([]);
      return;
    }

    try {
      setIsSearching(true);
      const results = await searchPlaces(query, {
        lat: location.coords.latitude,
        lng: location.coords.longitude
      });
      setSearchResults(results);
    } catch (error) {
      console.error('Erreur de recherche:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleResultClick = (result: SearchResult) => {
    setShowResults(false);
    setSearchQuery('');
    navigate(`/places/${result.id}`);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setShowUserMenu(false);
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src="/logo.svg" alt="Nexploria" className="h-8 w-8" />
              <span className="ml-2 text-xl font-bold text-blue-600">Nexploria</span>
            </Link>
          </div>

          <div className="flex-1 max-w-2xl mx-8" ref={searchRef}>
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher des lieux, événements..."
                value={searchQuery}
                onChange={handleSearch}
                className="w-full px-4 py-2 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:border-indigo-500"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              
              {showResults && (
                <div className="absolute w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
                  {isSearching ? (
                    <div className="p-4 text-center text-gray-500">
                      Recherche en cours...
                    </div>
                  ) : searchResults.length > 0 ? (
                    <div className="py-2">
                      {searchResults.map((result) => (
                        <button
                          key={result.id}
                          onClick={() => handleResultClick(result)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none"
                        >
                          <div className="font-medium text-gray-900">{result.name}</div>
                          <div className="text-sm text-gray-500">{result.address}</div>
                          <div className="text-xs text-gray-400">
                            {Math.round(result.distance)}m
                          </div>
                        </button>
                      ))}
                    </div>
                  ) : searchQuery.length > 0 ? (
                    <div className="p-4 text-center text-gray-500">
                      Aucun résultat trouvé
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <NotificationBell />

            <div className="relative" ref={userMenuRef}>
              <button 
                className="p-2 hover:bg-gray-100 rounded-full"
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <UserCircle className="h-6 w-6 text-gray-600" />
              </button>

              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 border border-gray-200">
                  {user ? (
                    <>
                      <div className="px-4 py-2 border-b border-gray-200">
                        <p className="font-medium text-gray-800">{profile?.name}</p>
                        <p className="text-sm text-gray-500">{user.email}</p>
                      </div>
                      <Link 
                        to="/profile"
                        onClick={() => setShowUserMenu(false)}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      >
                        Mon profil
                      </Link>
                      <button
                        onClick={handleSignOut}
                        className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50"
                      >
                        Déconnexion
                      </button>
                    </>
                  ) : (
                    <>
                      <Link 
                        to="/login"
                        onClick={() => setShowUserMenu(false)}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      >
                        Connexion
                      </Link>
                      <Link 
                        to="/register"
                        onClick={() => setShowUserMenu(false)}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      >
                        Inscription
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}