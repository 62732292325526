// Service AI simplifié sans dépendance OpenAI
// Utilise des recommandations statiques pour le moment

interface AIRecommendation {
  route?: ThematicRoute;
  suggestions?: Array<Event | Group>;
}

interface ThematicRoute {
  theme: string;
  description: string;
  points: RoutePoint[];
  totalDistance: number;
  estimatedDuration: number;
  difficulty: 'easy' | 'moderate' | 'challenging';
}

interface RoutePoint {
  placeId: string;
  name: string;
  location: {
    lat: number;
    lng: number;
  };
  description: string;
  duration: number;
}

interface Event {
  type: 'event';
  title: string;
  description: string;
  category: string;
}

interface Group {
  type: 'group';
  name: string;
  description: string;
  category: string;
}

export async function generateAIRecommendations(params: any): Promise<AIRecommendation> {
  // Simuler un délai de traitement
  await new Promise(resolve => setTimeout(resolve, 1000));

  // Générer des recommandations basées sur les paramètres
  const recommendations: AIRecommendation = {
    route: params.theme ? {
      theme: params.theme,
      description: `Un parcours thématique ${params.theme.toLowerCase()} adapté à vos préférences`,
      points: [
        {
          placeId: 'place1',
          name: 'Premier arrêt',
          location: { lat: 48.8584, lng: 2.2945 },
          description: 'Un lieu intéressant à découvrir',
          duration: 30
        },
        {
          placeId: 'place2',
          name: 'Deuxième arrêt',
          location: { lat: 48.8566, lng: 2.3522 },
          description: 'Une autre étape fascinante',
          duration: 45
        }
      ],
      totalDistance: 2500,
      estimatedDuration: 120,
      difficulty: params.difficulty || 'moderate'
    } : undefined,
    
    suggestions: [
      {
        type: 'event',
        title: 'Événement local',
        description: 'Un événement qui pourrait vous intéresser',
        category: 'culture'
      },
      {
        type: 'group',
        name: 'Groupe local',
        description: 'Un groupe partageant vos centres d\'intérêt',
        category: 'social'
      }
    ]
  };

  return recommendations;
}