import React, { useState } from 'react';
import { Map, Navigation, Clock, Compass } from 'lucide-react';
import { useSocialStore } from '../../stores/socialStore';
import { useGeolocation } from '../../hooks/useGeolocation';

export default function ThematicRoutes() {
  const { location } = useGeolocation();
  const { generateThematicRoute, loading, error } = useSocialStore();
  const [selectedTheme, setSelectedTheme] = useState('');
  const [currentRoute, setCurrentRoute] = useState<any>(null);

  const themes = [
    'Histoire et Culture',
    'Gastronomie Locale',
    'Art Urbain',
    'Nature et Parcs',
    'Shopping Local',
    'Architecture'
  ];

  const handleGenerateRoute = async () => {
    if (!location || !selectedTheme) return;

    try {
      const route = await generateThematicRoute({
        theme: selectedTheme,
        maxDistance: 5000,
        duration: 120,
        includeBusinesses: true,
        difficulty: 'moderate'
      });
      setCurrentRoute(route);
    } catch (error) {
      console.error('Erreur lors de la génération du parcours:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Parcours Thématiques</h1>
        <p className="text-gray-600">
          Découvrez la ville à travers des parcours personnalisés générés par IA
        </p>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
        {themes.map((theme) => (
          <button
            key={theme}
            onClick={() => setSelectedTheme(theme)}
            className={`p-4 rounded-lg text-center transition ${
              selectedTheme === theme
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            {theme}
          </button>
        ))}
      </div>

      {selectedTheme && (
        <div className="flex justify-center mb-8">
          <button
            onClick={handleGenerateRoute}
            disabled={loading}
            className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition disabled:opacity-50"
          >
            {loading ? 'Génération...' : 'Générer le parcours'}
          </button>
        </div>
      )}

      {currentRoute && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">
              {currentRoute.theme}
            </h2>
            <div className="flex items-center space-x-4">
              <span className="flex items-center text-gray-500">
                <Clock className="w-5 h-5 mr-1" />
                {currentRoute.estimatedDuration} min
              </span>
              <span className="flex items-center text-gray-500">
                <Compass className="w-5 h-5 mr-1" />
                {currentRoute.difficulty}
              </span>
            </div>
          </div>

          <p className="text-gray-600 mb-6">{currentRoute.description}</p>

          <div className="space-y-6">
            {currentRoute.points.map((point: any, index: number) => (
              <div
                key={point.placeId}
                className="flex items-start border-l-2 border-blue-500 pl-4"
              >
                <div className="flex-1">
                  <h3 className="font-medium text-gray-900">
                    {index + 1}. {point.name}
                  </h3>
                  <p className="text-gray-600 text-sm mt-1">
                    {point.description}
                  </p>
                  <div className="flex items-center mt-2 text-sm text-gray-500">
                    <Clock className="w-4 h-4 mr-1" />
                    {point.duration} min
                  </div>
                </div>
                <button
                  onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${point.location.lat},${point.location.lng}`, '_blank')}
                  className="ml-4 p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition"
                >
                  <Navigation className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <button
              onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${currentRoute.points[0].location.lat},${currentRoute.points[0].location.lng}`, '_blank')}
              className="w-full flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            >
              <Map className="w-5 h-5 mr-2" />
              Démarrer le parcours
            </button>
          </div>
        </div>
      )}
    </div>
  );
}