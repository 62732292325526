import { getDistance } from 'geolib';

export interface Location {
  lat: number;
  lng: number;
}

export function calculateDistance(from: Location, to: Location): number {
  return getDistance(
    { latitude: from.lat, longitude: from.lng },
    { latitude: to.lat, longitude: to.lng }
  );
}

export function isWithinRadius(center: Location, point: Location, radiusInMeters: number): boolean {
  const distance = calculateDistance(center, point);
  return distance <= radiusInMeters;
}

export function formatDistance(meters: number): string {
  if (meters < 1000) {
    return `${Math.round(meters)}m`;
  }
  return `${(meters / 1000).toFixed(1)}km`;
}