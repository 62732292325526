import { Loader } from '@googlemaps/js-api-loader';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places']
});

let placesService: google.maps.places.PlacesService | null = null;

async function initPlacesService() {
  if (!placesService) {
    await loader.load();
    const mapDiv = document.createElement('div');
    const map = new google.maps.Map(mapDiv, {
      center: { lat: 0, lng: 0 },
      zoom: 1
    });
    placesService = new google.maps.places.PlacesService(map);
  }
  return placesService;
}

export interface PlaceResult {
  id: string;
  name: string;
  location: {
    lat: number;
    lng: number;
  };
  address: string;
  rating?: number;
  photos: string[];
  isOpen: boolean;
  types: string[];
  distance?: number;
}

// Mapping des catégories pour la recherche
export const CATEGORY_MAPPING: Record<string, string> = {
  'culture': 'museum OR theater OR cultural center',
  'sport': 'gym OR sports center OR stadium',
  'musique': 'concert hall OR music venue',
  'gastronomie': 'restaurant OR bistro',
  'art': 'art gallery OR exhibition',
  'bien-etre': 'spa OR wellness center OR yoga',
  'nature': 'park OR garden',
  'technologie': 'tech hub OR coworking',
  'parents-familles': 'family activities OR playground',
  'hobbies-passions': 'hobby shop OR craft store',
  'animaux': 'pet shop OR veterinary OR animal park',
  'rencontres': 'social club OR community center',
  'evenements-locaux': 'event venue OR community hall',
  'activites-locales': 'activity center OR recreation'
};

export async function searchPlaces(
  query: string,
  location: { lat: number; lng: number },
  radius: number = 5000
): Promise<PlaceResult[]> {
  try {
    const service = await initPlacesService();
    
    return new Promise((resolve, reject) => {
      const request: google.maps.places.TextSearchRequest = {
        query,
        location: new google.maps.LatLng(location.lat, location.lng),
        radius
      };

      service.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          const places = results.map(place => ({
            id: place.place_id || '',
            name: place.name || '',
            location: {
              lat: place.geometry?.location?.lat() || 0,
              lng: place.geometry?.location?.lng() || 0
            },
            address: place.formatted_address || '',
            rating: place.rating,
            types: place.types || [],
            photos: place.photos?.map(photo => photo.getUrl({ maxWidth: 800, maxHeight: 600 })) || [],
            isOpen: place.opening_hours?.isOpen() || false
          }));
          resolve(places);
        } else {
          reject(new Error(`Places search failed: ${status}`));
        }
      });
    });
  } catch (error) {
    console.error('Error searching places:', error);
    throw error;
  }
}

export async function searchNearbyPlaces(
  location: { lat: number; lng: number },
  radius: number,
  type?: string
): Promise<PlaceResult[]> {
  try {
    const service = await initPlacesService();
    
    return new Promise((resolve, reject) => {
      const request: google.maps.places.PlaceSearchRequest = {
        location: new google.maps.LatLng(location.lat, location.lng),
        radius,
        type: type as google.maps.places.PlaceType | undefined
      };

      service.nearbySearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          const places = results.map(place => ({
            id: place.place_id || '',
            name: place.name || '',
            location: {
              lat: place.geometry?.location?.lat() || 0,
              lng: place.geometry?.location?.lng() || 0
            },
            address: place.vicinity || '',
            rating: place.rating,
            types: place.types || [],
            photos: place.photos?.map(photo => photo.getUrl({ maxWidth: 800, maxHeight: 600 })) || [],
            isOpen: place.opening_hours?.isOpen() || false
          }));
          resolve(places);
        } else {
          reject(new Error(`Places search failed: ${status}`));
        }
      });
    });
  } catch (error) {
    console.error('Error searching nearby places:', error);
    throw error;
  }
}

export async function getPlaceDetails(placeId: string): Promise<PlaceResult & {
  openingHours?: string[];
  website?: string;
  phone?: string;
  reviews?: Array<{
    author: string;
    rating: number;
    text: string;
    time: number;
  }>;
  priceLevel?: number;
}> {
  try {
    const service = await initPlacesService();

    return new Promise((resolve, reject) => {
      const request: google.maps.places.PlaceDetailsRequest = {
        placeId,
        fields: [
          'name',
          'formatted_address',
          'geometry',
          'photos',
          'rating',
          'opening_hours',
          'website',
          'formatted_phone_number',
          'reviews',
          'price_level',
          'types'
        ]
      };

      service.getDetails(request, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          resolve({
            id: result.place_id || '',
            name: result.name || '',
            address: result.formatted_address || '',
            location: {
              lat: result.geometry?.location?.lat() || 0,
              lng: result.geometry?.location?.lng() || 0
            },
            photos: result.photos?.map(photo => photo.getUrl({ maxWidth: 800, maxHeight: 600 })) || [],
            rating: result.rating,
            isOpen: result.opening_hours?.isOpen() || false,
            openingHours: result.opening_hours?.weekday_text,
            website: result.website,
            phone: result.formatted_phone_number,
            reviews: result.reviews?.map(review => ({
              author: review.author_name || '',
              rating: review.rating || 0,
              text: review.text || '',
              time: review.time || 0
            })),
            priceLevel: result.price_level,
            types: result.types || []
          });
        } else {
          reject(new Error(`Place details request failed: ${status}`));
        }
      });
    });
  } catch (error) {
    console.error('Error getting place details:', error);
    throw error;
  }
}