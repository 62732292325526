import { useState, useEffect } from 'react';

interface GeolocationState {
  location: GeolocationPosition | null;
  error: string | null;
  loading: boolean;
}

export function useGeolocation() {
  const [state, setState] = useState<GeolocationState>({
    location: null,
    error: null,
    loading: true
  });

  useEffect(() => {
    if (!navigator.geolocation) {
      setState(prev => ({
        ...prev,
        error: 'La géolocalisation n\'est pas supportée par votre navigateur',
        loading: false
      }));
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setState({
          location: position,
          error: null,
          loading: false
        });
      },
      (error) => {
        let message = 'Une erreur est survenue';
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = 'Vous devez autoriser la géolocalisation pour utiliser cette fonctionnalité';
            break;
          case error.POSITION_UNAVAILABLE:
            message = 'La position n\'est pas disponible';
            break;
          case error.TIMEOUT:
            message = 'La demande de géolocalisation a expiré';
            break;
        }
        setState({
          location: null,
          error: message,
          loading: false
        });
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 5000
      }
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return state;
}