import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { MapPin, Users, ArrowLeft, Navigation } from 'lucide-react';
import { searchPlaces, PlaceResult, CATEGORY_MAPPING } from '../../services/googlePlaces';
import { useGeolocation } from '../../hooks/useGeolocation';

export default function CategoryEvents() {
  const { category } = useParams<{ category: string }>();
  const { location } = useGeolocation();
  const [places, setPlaces] = useState<PlaceResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPlaces() {
      if (!location || !category || hasLoaded) return;

      try {
        setLoading(true);
        setError(null);
        const categoryKey = category.toLowerCase();
        const searchTerm = CATEGORY_MAPPING[categoryKey] || category;
        
        const searchResults = await searchPlaces(
          searchTerm,
          {
            lat: location.coords.latitude,
            lng: location.coords.longitude
          },
          5000
        );
        setPlaces(searchResults);
        setHasLoaded(true);
      } catch (err) {
        console.error('Erreur lors du chargement des lieux:', err);
        setError('Impossible de charger les lieux. Veuillez réessayer plus tard.');
      } finally {
        setLoading(false);
      }
    }

    fetchPlaces();
  }, [category, location, hasLoaded]);

  const handlePlaceClick = (place: PlaceResult) => {
    navigate(`/places/${place.id}`, {
      state: {
        name: place.name,
        address: place.address,
        photos: place.photos,
        rating: place.rating,
        types: place.types,
        location: place.location
      }
    });
  };

  if (loading && !hasLoaded) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-4">
      <div className="mb-4">
        <Link to="/" className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-2">
          <ArrowLeft className="w-4 h-4 mr-1" />
          Retour
        </Link>
        <h1 className="text-xl font-bold text-gray-900 capitalize">
          {category?.replace(/-/g, ' ')}
        </h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
        {places.map((place) => (
          <div 
            key={place.id}
            onClick={() => handlePlaceClick(place)}
            className="bg-white rounded-lg shadow-sm hover:shadow-md transition cursor-pointer flex overflow-hidden h-32"
          >
            <div className="w-32 h-full flex-shrink-0">
              <img
                src={place.photos[0] || `https://source.unsplash.com/800x600/?${category}`}
                alt={place.name}
                className="w-full h-full object-cover"
              />
            </div>
            
            <div className="flex-1 p-3 flex flex-col justify-between">
              <div>
                <h3 className="font-medium text-gray-900 line-clamp-1">{place.name}</h3>
                <div className="flex items-center text-xs text-gray-500 mt-1">
                  <MapPin className="w-3 h-3 mr-1 flex-shrink-0" />
                  <span className="line-clamp-1">{place.address}</span>
                </div>
              </div>

              <div className="flex items-center justify-between mt-2">
                {place.rating && (
                  <div className="flex items-center text-xs text-gray-600">
                    <Users className="w-3 h-3 mr-1" />
                    <span>{place.rating}/5</span>
                  </div>
                )}
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`https://www.google.com/maps/search/?api=1&query=${place.location.lat},${place.location.lng}`, '_blank');
                  }}
                  className="p-1.5 bg-blue-50 text-blue-600 rounded-full hover:bg-blue-100 transition"
                >
                  <Navigation className="w-3 h-3" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {places.length === 0 && !loading && (
        <div className="text-center py-8">
          <p className="text-gray-600">
            Aucun lieu trouvé dans cette catégorie pour le moment.
          </p>
        </div>
      )}
    </div>
  );
}