import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import { UserProfile } from '../../stores/authStore';

const INTERESTS = [
  'Restaurant', 'Shopping', 'Culture', 'Sport', 'Musique', 
  'Art', 'Cinéma', 'Théâtre', 'Nature', 'Technologie',
  'Bien-être', 'Gastronomie', 'Histoire', 'Mode'
];

const DISTANCE_OPTIONS = [
  { value: 500, label: '500m' },
  { value: 1000, label: '1km' },
  { value: 2000, label: '2km' },
  { value: 5000, label: '5km' },
  { value: 10000, label: '10km' }
];

const FREQUENCIES = [
  { value: 'realtime', label: 'Temps réel' },
  { value: 'hourly', label: 'Toutes les heures' },
  { value: 'daily', label: 'Une fois par jour' },
  { value: 'weekly', label: 'Une fois par semaine' }
];

interface NotificationSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  profile: UserProfile;
}

export default function NotificationSettingsModal({ isOpen, onClose, profile }: NotificationSettingsModalProps) {
  const { updateProfile } = useAuthStore();
  const [settings, setSettings] = useState({
    nearbyPlaces: profile.notificationPreferences?.nearbyPlaces ?? true,
    events: profile.notificationPreferences?.events ?? true,
    radius: profile.notificationPreferences?.radius ?? 1000,
    interests: profile.notificationPreferences?.interests ?? [],
    frequency: profile.notificationPreferences?.frequency ?? 'realtime',
    quietHours: profile.notificationPreferences?.quietHours ?? {
      start: '22:00',
      end: '07:00'
    }
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await updateProfile({
        ...profile,
        notificationPreferences: settings
      });
      onClose();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des paramètres:', error);
    } finally {
      setIsSaving(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Paramètres de notification</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-6">
          {/* Types de notifications */}
          <div>
            <h3 className="text-lg font-medium mb-4">Types de notifications</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium">Lieux à proximité</p>
                  <p className="text-sm text-gray-500">Notifications pour les nouveaux lieux</p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={settings.nearbyPlaces}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      nearbyPlaces: e.target.checked
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium">Événements</p>
                  <p className="text-sm text-gray-500">Notifications pour les événements à venir</p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={settings.events}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      events: e.target.checked
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>

          {/* Rayon de notification */}
          <div>
            <h3 className="text-lg font-medium mb-4">Rayon de notification</h3>
            <select
              value={settings.radius}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                radius: parseInt(e.target.value)
              }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {DISTANCE_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Fréquence des notifications */}
          <div>
            <h3 className="text-lg font-medium mb-4">Fréquence des notifications</h3>
            <select
              value={settings.frequency}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                frequency: e.target.value
              }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {FREQUENCIES.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Centres d'intérêt */}
          <div>
            <h3 className="text-lg font-medium mb-4">Centres d'intérêt</h3>
            <div className="flex flex-wrap gap-2">
              {INTERESTS.map((interest) => (
                <button
                  key={interest}
                  onClick={() => {
                    const newInterests = settings.interests.includes(interest)
                      ? settings.interests.filter(i => i !== interest)
                      : [...settings.interests, interest];
                    setSettings(prev => ({
                      ...prev,
                      interests: newInterests
                    }));
                  }}
                  className={`px-3 py-1 rounded-full text-sm ${
                    settings.interests.includes(interest)
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {interest}
                </button>
              ))}
            </div>
          </div>

          {/* Horaires silencieux */}
          <div>
            <h3 className="text-lg font-medium mb-4">Horaires silencieux</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-gray-600 mb-1">Début</label>
                <input
                  type="time"
                  value={settings.quietHours.start}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    quietHours: {
                      ...prev.quietHours,
                      start: e.target.value
                    }
                  }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-600 mb-1">Fin</label>
                <input
                  type="time"
                  value={settings.quietHours.end}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    quietHours: {
                      ...prev.quietHours,
                      end: e.target.value
                    }
                  }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Boutons d'action */}
        <div className="mt-6 flex justify-end space-x-3 border-t pt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
            disabled={isSaving}
          >
            Annuler
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {isSaving ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></span>
                Enregistrement...
              </>
            ) : (
              <>
                <Save className="w-4 h-4 mr-2" />
                Enregistrer
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}