import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import Home from '../components/Home';
import Feed from '../components/Feed';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';
import Events from '../components/social/Events';
import Groups from '../components/social/Groups';
import Messages from '../components/social/Messages';
import SpontaneousMeetups from '../components/social/SpontaneousMeetups';
import ThematicRoutes from '../components/social/ThematicRoutes';
import CategoryEvents from '../components/events/CategoryEvents';
import UserProfile from '../components/profile/UserProfile';
import SearchResults from '../components/search/SearchResults';
import PlaceDetails from '../components/PlaceDetails';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthStore();
  return user ? <>{children}</> : <Navigate to="/login" />;
};

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route path="/search" element={<SearchResults />} />
      <Route path="/places/:id" element={<PlaceDetails />} />
      
      {/* Catégories et événements */}
      <Route path="/events" element={<Events />} />
      <Route path="/events/category/:category" element={<CategoryEvents />} />
      
      {/* Routes protégées */}
      <Route path="/feed" element={<PrivateRoute><Feed /></PrivateRoute>} />
      <Route path="/groups" element={<PrivateRoute><Groups /></PrivateRoute>} />
      <Route path="/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
      <Route path="/meetups" element={<PrivateRoute><SpontaneousMeetups /></PrivateRoute>} />
      <Route path="/routes" element={<PrivateRoute><ThematicRoutes /></PrivateRoute>} />
      <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
      
      {/* Fallback route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}