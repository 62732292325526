import React, { useState } from 'react';
import { useAuthStore } from '../../stores/authStore';
import { Settings, MapPin, Star, Navigation, History, Activity, ArrowLeft } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import NotificationSettingsModal from './NotificationSettingsModal';
import { Link } from 'react-router-dom';

export default function UserProfile() {
  const { profile, user } = useAuthStore();
  const [showNotificationSettings, setShowNotificationSettings] = useState(false);

  if (!profile || !user) return null;

  const navigationStats = {
    totalDistance: 125.4,
    placesVisited: 48,
    eventsAttended: 15,
    reviewsPosted: 23
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Back button */}
      <Link 
        to="/" 
        className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="w-5 h-5 mr-1" />
        Retour
      </Link>

      <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-8">
        {/* Banner - reduced height */}
        <div className="h-24 bg-gradient-to-r from-blue-500 to-indigo-600 relative">
          <div className="absolute bottom-4 right-4 p-2 bg-white/20 rounded-full text-white">
            <Settings className="w-5 h-5" />
          </div>
        </div>
        
        <div className="px-6 pb-6">
          {/* Profile section with adjusted margins */}
          <div className="flex items-end justify-between mt-4">
            <div className="flex items-end">
              <div className="w-24 h-24 rounded-full border-4 border-white bg-gray-200 overflow-hidden -mt-12">
                <img
                  src={profile.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(profile.name)}&background=random`}
                  alt={profile.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="ml-4 mb-2">
                <h1 className="text-2xl font-bold">{profile.name}</h1>
                <p className="text-gray-600">{user.email}</p>
              </div>
            </div>
            
            <button
              onClick={() => setShowNotificationSettings(true)}
              className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
            >
              <Settings className="w-4 h-4 mr-2" />
              Paramètres de notification
            </button>
          </div>

          <div className="mt-6 grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Distance totale</p>
                  <p className="text-xl font-bold">{navigationStats.totalDistance} km</p>
                </div>
                <Navigation className="w-8 h-8 text-blue-500" />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Lieux visités</p>
                  <p className="text-xl font-bold">{navigationStats.placesVisited}</p>
                </div>
                <MapPin className="w-8 h-8 text-green-500" />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Événements</p>
                  <p className="text-xl font-bold">{navigationStats.eventsAttended}</p>
                </div>
                <Activity className="w-8 h-8 text-purple-500" />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Avis postés</p>
                  <p className="text-xl font-bold">{navigationStats.reviewsPosted}</p>
                </div>
                <Star className="w-8 h-8 text-yellow-500" />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-3">Centres d'intérêt</h2>
            <div className="flex flex-wrap gap-2">
              {profile.interests?.map((interest) => (
                <span
                  key={interest}
                  className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm"
                >
                  {interest}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Historique d'activité */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-4">Historique d'activité</h2>
        <div className="space-y-4">
          {/* Exemple d'activités */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center">
              <MapPin className="w-5 h-5 text-blue-500 mr-3" />
              <div>
                <p className="font-medium">Visite au Café des Arts</p>
                <p className="text-sm text-gray-500">Il y a 2 heures</p>
              </div>
            </div>
            <Star className="w-5 h-5 text-yellow-500" />
          </div>
        </div>
      </div>

      {/* Modal des paramètres de notification */}
      {showNotificationSettings && (
        <NotificationSettingsModal
          isOpen={showNotificationSettings}
          onClose={() => setShowNotificationSettings(false)}
          profile={profile}
        />
      )}
    </div>
  );
}