import React, { useEffect, useState } from 'react';
import { MapPin, Clock, Star } from 'lucide-react';
import { useGeolocation } from '../hooks/useGeolocation';
import { useAuthStore } from '../stores/authStore';
import { searchPlaces, searchNearbyPlaces, PlaceResult } from '../services/googlePlaces';

export default function Feed() {
  const { location, error: geoError } = useGeolocation();
  const { profile, searchRadius } = useAuthStore();
  const [places, setPlaces] = useState<PlaceResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    async function fetchPlaces() {
      if (location) {
        try {
          setLoading(true);
          setError(null);
          let results;
          
          if (searchQuery) {
            results = await searchPlaces(
              searchQuery,
              {
                lat: location.coords.latitude,
                lng: location.coords.longitude
              },
              searchRadius
            );
          } else {
            results = await searchNearbyPlaces(
              {
                lat: location.coords.latitude,
                lng: location.coords.longitude
              },
              searchRadius
            );
          }
          
          setPlaces(results);
        } catch (err) {
          console.error('Error fetching places:', err);
          setError('Erreur lors de la récupération des lieux');
        } finally {
          setLoading(false);
        }
      }
    }

    fetchPlaces();
  }, [location, searchRadius, searchQuery]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || geoError) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500">{error || geoError}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold text-gray-900">À proximité</h1>
        <select
          value={searchRadius}
          onChange={(e) => useAuthStore.getState().updateSearchRadius(Number(e.target.value))}
          className="px-4 py-2 border border-gray-300 rounded-lg bg-white"
        >
          <option value="500">500m</option>
          <option value="1000">1km</option>
          <option value="2000">2km</option>
          <option value="5000">5km</option>
        </select>
      </div>

      <div className="grid gap-6">
        {places.map((place) => (
          <div key={place.id} className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition">
            <div className="flex">
              <div className="w-48 h-48">
                <img
                  src={place.photos[0] || "https://images.unsplash.com/photo-1554118811-1e0d58224f24?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80"}
                  alt={place.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1 p-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold text-gray-900">{place.name}</h2>
                  <span className="flex items-center text-sm text-gray-500">
                    <MapPin className="h-4 w-4 mr-1" />
                    {place.distance ? `${place.distance}m` : place.address}
                  </span>
                </div>
                
                <div className="mt-2 flex items-center space-x-3">
                  <span className="px-3 py-1 text-sm text-blue-600 bg-blue-50 rounded-full">
                    {place.types[0]}
                  </span>
                  <span className="flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    {place.isOpen ? 'Ouvert' : 'Fermé'}
                  </span>
                  {place.rating && (
                    <span className="flex items-center text-sm text-yellow-500">
                      <Star className="h-4 w-4 mr-1 fill-current" />
                      {place.rating}
                    </span>
                  )}
                </div>

                <div className="mt-4 flex items-center space-x-4">
                  <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">
                    Plus d'infos
                  </button>
                  <button className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition">
                    Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}